import axios from "axios";
import API_CONFIG from "../apiConfig";
import axiosInstance from "../axiosInstance";

const get_Dashboard_Data = (currentPage) => {
  return axiosInstance.get(`${API_CONFIG.baseURL}dashboard?page=${currentPage}`);
};

const delete_Resume = (id) => {
  return axiosInstance.delete(`${API_CONFIG.baseURL}dashboard/delete_resume/${id}`);
};

const Clone_Resume = (id) => {
  return axiosInstance.post(`${API_CONFIG.baseURL}dashboard/clone_resume/${id}`);
};

export {get_Dashboard_Data,delete_Resume,Clone_Resume};