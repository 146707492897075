import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { forgotPassword } from "../../api/services/userService";
import { toast } from "react-toastify";

const NewPasswordForm = ({
  setOtpSection,
  forgotOption,
  email,
  mobileForm,
}) => {
  const [newpPasswordError, setNewpPasswordError] = useState({});
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [newPasswordForm, setNewPasswordForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleNewPassword = async (e) => {
    setNewPasswordForm({ ...newPasswordForm, [e.target.name]: e.target.value });
  };

  const onNewPasswordFormSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
     setSubmitBtnLoading(true);
      const fData = {
        email: email,
        password: newPasswordForm?.newPassword,
        password_confirmation: newPasswordForm?.confirmNewPassword,
      };
      try {
        const response = await forgotPassword(fData);
        if(response?.data?.success){
          toast.success(response?.data?.message,{
           position: "top-center",
         });
         setOtpSection(false);
         navigate("/login")
         }else{
          toast.error(response?.data?.message,{
           position: "top-center",
         });
        }
      
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitBtnLoading(false);
      }
    }
  };

  const validation = () => {
    const newErrors = {};
    if (newPasswordForm.newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least 8 characters long.";
    } else if (!/[A-Z]/.test(newPasswordForm.newPassword)) {
      newErrors.newPassword =
        "Password must contain at least one uppercase letter.";
    } else if (!/[a-z]/.test(newPasswordForm.newPassword)) {
      newErrors.newPassword =
        "Password must contain at least one lowercase letter.";
    } else if (!/[0-9]/.test(newPasswordForm.newPassword)) {
      newErrors.newPassword = "Password must contain at least one number.";
    } else if (!/[!@#$%^&*]/.test(newPasswordForm.newPassword)) {
      newErrors.newPassword =
        "Password must contain at least one special character.";
    }
    // else if (/(.)\1{2,}/.test(newPasswordForm.newPassword)) {
    //   newErrors.newPassword = "Your password must not contain more than 2 consecutive identical characters.";
    // }
    else {
      newErrors.newPassword = "";
    }

    if (
      !newPasswordForm.confirmNewPassword ||
      !newPasswordForm.confirmNewPassword.trim() === ""
    ) {
      newErrors.confirmNewPassword = "Confirm password is required";
    } else if (
      newPasswordForm.newPassword !== newPasswordForm?.confirmNewPassword
    ) {
      newErrors.confirmNewPassword = "Passwords do not match.";
    } else {
      newErrors.confirmNewPassword = "";
    }

    setNewpPasswordError(newErrors);
    // Return true if no errors, false otherwise
    return Object.keys(newErrors).every((key) => newErrors[key] === "");
  };

  return (
    <>
      <Tooltip id="password-example-tooltip" className="tooltip_style">
        <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <span className="tooltip-password-head">Password must contain:</span>
          <span className="mt-2">-At least one uppercase letter </span>
          <span>-At least one lowercase letter</span>
          <span>-At least one special character</span>
          <span>-At least one number</span>
          <span>-Be at least 8 characters</span>
          {/* <span>-Your password must not contain more than 2 consecutive identical characters.</span> */}
        </div>
      </Tooltip>
      <form onSubmit={onNewPasswordFormSubmit} className="mt-3">
        <div>
          <div className="form-group mb-0">
            {/* <label className="forgotpassword-label">New Password</label> */}
            <input
              data-tooltip-id="password-example-tooltip"
              type="password"
              className="form-control  rounded-3"
              placeholder="Enter new password"
              name="newPassword"
              value={newPasswordForm?.newPassword}
              onChange={handleNewPassword}
            />
          </div>
          {newpPasswordError?.newPassword && (
            <span className="" style={{ color: "red", fontSize: "13px" }}>
              {newpPasswordError?.newPassword}
            </span>
          )}
        </div>

        <div className="mt-2">
          <div className="form-group mb-0">
            {/* <label className="forgotpassword-label">Confirm New Password</label> */}
            <input
              type="password"
              className="form-control  rounded-3"
              placeholder="Enter confirm new password"
              name="confirmNewPassword"
              value={newPasswordForm?.confirmNewPassword}
              onChange={handleNewPassword}
            />
          </div>
          {newpPasswordError?.confirmNewPassword && (
            <span className="" style={{ color: "red", fontSize: "13px" }}>
              {newpPasswordError?.confirmNewPassword}
            </span>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <button type="submit" className="login-signup-btn mt-2">
            {submitBtnLoading && (
              <div
                className="spinner-border text-light"
                style={{ height: 15, width: 15 }}
                role="status">
              </div>
            )}
            Submit
          </button>
          <Link to="/login" className="register-btn mt-4">
            Back To Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default NewPasswordForm;
