import "../../assets/Styles/Section10.css";
import videoplay from "../../assets/Images/quick-and-easy.mp4"
import { useState } from "react";

const Section10 = () => {

    const [activePanel, setActivePanel] = useState(1);

    const accordionItems = [
        { id: 1, title: "Easily edit online", content: "Creating the perfectly formatted, custom tailored resume has never been easier." },
        {
            id: 2, title: "Add AI pre-written phrases", content: "Beat writer’s block with AI pre-written and tested phrases that successfully communicate your experience and skills."
        },
        { id: 3, title: "Automatic spell-checker", content: "This is the content of Panel 3Our built-in spell-checker will protect you from mistakes and typos. Remove all the grammar anxiety from resume writing!" },

        { id: 4, title: "Export to anything", content: "You’re in control of your resume, our exports work perfectly with Word or any other app." },
    ];

    const togglePanel = (id) => {
        setActivePanel(activePanel === id ? null : id);
    }

    return (
        <>
            <div className="sec10-head">
                <h2>Effortlessly make a job-worthy resume and cover letter that gets you hired faster</h2>
                <div className="sec10-main">

                    <div className='sec10-video'>
                        <video autoPlay loop muted>
                            <source src={videoplay} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className='sec10-content'>
                        <div className="accordion-container">
                            {accordionItems.map((item) => (
                                <div key={item.id} className="accordion-item">
                                    {/* Accordion Header */}
                                    <div
                                        className="accordion-header"
                                        onClick={() => togglePanel(item.id)}
                                    >
                                        {item.title}
                                        <span
                                            className={`chevron ${activePanel === item.id ? "rotate" : ""}`}
                                        >
                                            ▶
                                        </span>
                                    </div>

                                    {/* Accordion Content */}
                                    <div
                                        className={`accordion-content ${activePanel === item.id ? "open" : ""}`}
                                    >
                                        {item.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section10;