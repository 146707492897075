import * as userAPI from "../endpoints/user";

const login = async (fData) => {
  try {
    const response = await userAPI.loginUser(fData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const register = async (fData) => {
  try {
    const response = await userAPI.registerUser(fData);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

const email_ForgotPassword = async (fData) => {
  try {
    const response = await userAPI.email_Forgot_Password(fData);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

const forget_VerifyOtp = async (fData) => {
  try {
    const response = await userAPI.forget_Verify_Otp(fData);
    return response;
  } catch (err) {
    throw err;
  }
};

const forgotPassword = async (fData) => {
  try {
    const response = await userAPI.forgot_Password(fData);
    return response;
  } catch (err) {
    throw err;
  }
};

const get_UserInfo = async () => {
  try {
    const response = await userAPI.get_User_Info();
    return response;
  } catch (err) {
    throw err;
  }
};

const UpdateProfile = async (fData) => {
  try {
    const response = await userAPI.update_Profile(fData);
    return response;
  } catch (err) {
    throw err;
  }
};

const getCountryStatesCity= async()=>{
  try {
   const response = await userAPI.getcountry_States_City();
   return response;
  }catch(err) {
   throw err;
  }
}

const get_EditProfile = async()=>{
  try {
   const response = await userAPI.get_Edit_Profile();
   return response;
  }catch(err) {
   throw err;
  }
}

const getProfileDropdownVal = async()=>{
  try {
   const response = await userAPI.getProfile_DropdownVal();
   return response;
  }catch(err) {
   throw err;
  }
}


export {
  login,
  register,
  email_ForgotPassword,
  forget_VerifyOtp,
  forgotPassword,
  get_UserInfo,
  UpdateProfile,
  getCountryStatesCity,
  get_EditProfile,
  getProfileDropdownVal
};