import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../../assets/Styles/VideoSwiperTemplate.css"
import videoImage1 from "../../assets/Images/4_step_driver_resume_tutorial.avif"
import videoImage2 from "../../assets/Images/5-tips-for-work-life-balance.avif"
import videoImage3 from "../../assets/Images/thank-you-letter-job-offer__2_.avif"
import videoImage4 from "../../assets/Images/3_tips_for_engineering_resumes.avif"

import { Navigation, Pagination } from 'swiper/modules';

const VideoSwiperTemplate = () => {
  const swiperRef = useRef(null);

  const videos = [
    {
      id: 1,
      thumbnail: videoImage1,
      videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      title: "University Applications: How to write your cover letter",
      tags: ["Video", "Cover Letter"]
    },
    {
      id: 2,
      thumbnail: videoImage2,
      videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
      title: "How to write a driver resume? 4 top tips (tutorial)",
      tags: ["Video", "Resume Help"]
    },
    {
      id: 3,
      thumbnail: videoImage3,
      videoUrl: "https://www.youtube.com/embed/9bZkp7q19f0",
      title: "How to write a top engineering resume (+ example)",
      tags: ["Video", "Resume Help"]
    },
    {
      id: 4,
      thumbnail: videoImage4,
      videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY",
      title: "University Applications: How to write your cover letter",
      tags: ["Video", "Job Interview"]
    }
  ];



  const VideoUrlOpen = (url) => {
    window.open(url);
  };

  return (
    <div className="expert-videos-first_div">
      <div className="expert-videos">
        <div className="header">
          <h2>Expert videos</h2>

          <div className="header-buttons">
            <button className="all-videos-btn">All videos</button>
            {/* <div className="swiper-buttons">
            <button className="swiper-button-prev" onClick={() => swiperRef.current.swiper.slidePrev()}></button>
            <button className="swiper-button-next" onClick={() => swiperRef.current.swiper.slideNext()}></button>
          </div> */}
          </div>
        </div>

        <Swiper
          // ref={swiperRef}
          slidesPerView={3}
          spaceBetween={20}
          // navigation={{
          //   prevEl: '.swiper-button-prev',
          //   nextEl: '.swiper-button-next',
          // }}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className="mySwiper"
        >

          {videos?.map(video => (
            <SwiperSlide key={video.id}>
              <div className="video-card">

                {/* {video.videoUrl.includes('youtube') ? (
                <iframe
                  className="video-thumbnail"
                  width="100%"
                  height="315"
                  src={video.videoUrl}
                  allowFullScreen
                  title={video.title}
                />
              ) : ( */}
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="video-thumbnail"
                  onClick={() => VideoUrlOpen(video.videoUrl)}
                  title={`open video url ${video.videoUrl}`}
                />
                {/* )} */}

                <div className="video-info">
                  <p className="tags">
                    {video.tags.map(tag => (
                      <span key={tag} className="tag">{tag}</span>
                    ))}
                  </p>
                  <h4>{video.title}</h4>
                </div>
              </div>
            </SwiperSlide>
          ))}

        </Swiper>
      </div>
    </div>
  );
};

export default VideoSwiperTemplate;



