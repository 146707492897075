
import { Box, Button, SimpleGrid, useDisclosure } from "@chakra-ui/react";

import { useEffect, useState } from "react";
// import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
// import DevelopmentTable from "../../../views/admin/dataTables/components/DevelopmentTable"
// import CheckTable from "../../../views/admin/dataTables/components/CheckTable";
// import ColumnsTable from "../../../views/admin/dataTables/components/ColumnsTable";
import ComplexTable from "../dataTables/components/ComplexTable";
import {
  // columnsDataDevelopment,
  // columnsDataCheck,
  // columnsDataColumns,
  columnsDataComplex,
} from "../dataTables/variables/columnsData";
// import tableDataDevelopment from "../../../views/admin/dataTables/variables/tableDataDevelopment.json";
// import tableDataCheck from "../../../views/admin/dataTables/variables/tableDataCheck.json";
// import tableDataColumns from "../../../views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "../dataTables/variables/tableDataComplex.json";
import React from "react";
import MyResumeTable from "./components/MyResumeTable";
import { getDashboardData } from "../../../api/services/memberDashboardService";


export default function Settings() {
  const [dashboardData,setDashboardData]=useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading]=useState(false);

  const getDashboard_Data=async()=>{
    setLoading(true);
    try{
      const response =await getDashboardData(currentPage);
      const data = response?.data?.resume
      setDashboardData(data);
    }catch(err){
      console.log(err)
    }finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    getDashboard_Data();
  },[currentPage]);
 
  return (<>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
   
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/* <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}

        {
        dashboardData?.data?.length > 0 &&
         <MyResumeTable
          //columnsData={columnsDataComplex}
          tableData={dashboardData}
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage}
          loading={loading}
          getDashboard_Data={getDashboard_Data}
         />
        }
      </SimpleGrid>
    </Box>
    </>
  );
}
