import "../../assets/Styles/NewNavbar.css";
import NavLogo from "../../assets/Images/NavLogo.png";
import {
  MdOutlineKeyboardArrowDown,
  MdCreate,
  MdClose,
  MdMenu,
} from "react-icons/md";
import { FaFire } from "react-icons/fa";
import { FaHandFist } from "react-icons/fa6";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isTokenValid } from "../../AuthCheck";
import { get_UserInfo } from "../../api/services/userService";
import { useDispatch, useSelector } from "react-redux";
import { PROFILEINFO } from "../../Redux/Actions";
import ProfileSection from "./navbarComponent/ProfileSection";

const NavBar = () => {
  const menuRef = React.useRef(null);
  const [hoverResumeServices, setHoverResumeServices] = useState(false);
  const [hoverCoverLetterServices, setHoverCoverLetterServices] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const tokenValid = isTokenValid();
  const dispatch = useDispatch();
  const ProfileInfo = useSelector((data) => data?.userReducer?.userData);
  const isValidToken = isTokenValid();

  const getUserData = async () => {
    try {
      const response = await get_UserInfo();
      const data = response?.data?.data;
      dispatch(PROFILEINFO(data));
    } catch (err) {
      if(err?.status == 401){
        localStorage.removeItem('LoginToken');
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (isValidToken) {
      getUserData();
    }
  }, [isValidToken]);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (
      currentScrollY > 100 &&
      currentScrollY < 800 &&
      currentScrollY > lastScrollY
    ) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
    window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  // Close menu with Escape key
  const handleKeyUp = (event) => {
    if (event.key === "Escape") {
      closeMenu();
    }
  };

  // Event listeners for outside click and keyup
  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <nav className={`navbar ${isNavbarVisible ? "visible" : "hidden"}`}>
        <div className="navbar-left">
          <a href="/">
            <img src={NavLogo} alt="Logo" className="logo" />
          </a>
        </div>
        <div className="navbar-center">
          <ul className="navbar-links">
            <li
              className="dropdown"
              onMouseEnter={() => setHoverResumeServices(true)}
              onMouseLeave={() => setHoverResumeServices(false)}>
              <a className={`${hoverResumeServices ? "nav-link-btn-active" : "nav-link-btn"} resume-drop d-flex align-items-center`} href="#">
                Resume
                <MdOutlineKeyboardArrowDown size={20} />
              </a>
              <div
                className={`dropdown-content mega-menu ${hoverResumeServices ? "show" : ""}`}
                onMouseLeave={() => setHoverResumeServices(false)}>
                <div className="mega-menu-container">
                  <div className="resume-templates">
                    <h5>Resume Templates</h5>
                    <ul>
                      <li>
                        <Link to="/templatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <FaFire size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Simple
                        </Link>
                        <p>
                          Clean, timeless templates with a classic balanced
                          structure. A perfect basic canvas
                        </p>
                      </li>
                      <li>
                        <a href="/MyResume" className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"/>
                          </span>
                          My Resume
                        </a>
                        <p>
                          A current and stylish feel for forward-thinking
                          candidates in innovative fields
                        </p>
                      </li>
                      <li>
                        <Link to="/templatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"/>
                          </span>
                          Professional
                        </Link>
                        <p>
                          Job-winning templates to showcase professionalism,
                          dependability, and expertise
                        </p>
                      </li>
 
                      <li>
                        <Link to="/templatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <MdCreate size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Creative
                        </Link>
                        <p>
                          A bold, original feel perfect for artistic fields and
                          contemporary companies
                        </p>
                      </li>
                    </ul>
                    {/* <div className="row">
                     <div className="col-1">
                      <a href="#" className="view-all">View all</a>
                     </div>
                    </div> */}

                  </div>
                </div>
              </div>
            </li>

            <li className="dropdown"
              onMouseEnter={() => setHoverCoverLetterServices(true)}
              onMouseLeave={() => setHoverCoverLetterServices(false)}>
              <a href="#" className={`${hoverCoverLetterServices ? "nav-link-btn-active" : "nav-link-btn"} d-flex align-items-center`}>
                Cover Letter
                <MdOutlineKeyboardArrowDown size={20} />
              </a>
              <div className={`dropdown-content mega-menu ${hoverCoverLetterServices ? "show" : ""}`}
                onMouseLeave={() => setHoverCoverLetterServices(false)}>
                <div className="mega-menu-container">
                  <div className="resume-templates">
                    <h5>Cover Letter Templates</h5>
                    <ul>
                      <li>
                        <Link to="/cvtemplatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <FaFire size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Simple
                        </Link>
                        <p> Clean, straightforward templates aiming to keep the focus on your letter’s writing and content </p>
                      </li>

                      <li>
                        <Link to="/mycv"
                          className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"/>
                          </span>
                          My Cv
                        </Link>
                        <p> A current and stylish feel for forward-thinking candidates in innovative fields </p>
                      </li>

                      <li>
                        <Link to="/cvtemplatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"/>
                          </span>
                          Professional
                        </Link>
                        <p> Polished designs to help you highlight your knowledge and expertise in formal fields </p>
                      </li>
 
                      <li>
                       <div>
                        <Link to="/cvtemplatecard" className="d-flex align-items-center gap-1">
                          <span>
                            <MdCreate size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Creative
                        </Link>
                        </div>
                        <p> Stylish templates defined by a unique approach to show off your originality and inspiration </p>
                      </li>
                    </ul>
                    {/* <div className="row">
                     <div className="col-1">
                      <a href="#" className="view-all">View all</a>
                     </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="#" className="nav-link-btn">Blog</a>
            </li>
            <li>
              <a href="/pricingplan" className="nav-link-btn">Pricing</a>
            </li>

            <div className="navbar-right">
              {!tokenValid && (
                <Link className="my-account" to="/login">
                  Login
                </Link>
              )}

              {tokenValid && (
                <Link className="btn-build-resume text-white" to="/member/default">
                  Dashboard
                </Link>
              )}
            </div>
            <li>
              {/*profile icons */}
              {tokenValid && <ProfileSection ProfileInfo={ProfileInfo} />}
            </li>
          </ul>

          {/* Hamburger Menu  Phone screen*/}
          <div className="hamburger" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <MdClose size={28} /> : <MdMenu size={28} />}
          </div>

          {/* Mobile Menu */}
          <ul className={`menu ${isMobileMenuOpen ? "active" : ""}`} ref={menuRef}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/MyResume">My Resume</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/admin/default">Dashboard</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default NavBar;
