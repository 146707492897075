import { createStore, combineReducers } from "redux"
import {
    selectedTemplateReducer,
    EducationInfo, workExpReducer,
    personalInfoReducer,
    SkillReducer,
} from "./Reducers"
import userReducer from "./UserReducer"






export const store = createStore(
    combineReducers({
        selectedTemplateReducer,
        EducationInfo, workExpReducer,
        personalInfoReducer,
        SkillReducer,
        userReducer
    }), {}
)