import React from 'react';
import "../../assets/Styles/Section3.css"

import img1 from "../../assets/Images/sec3-1.png"
import img2 from "../../assets/Images/sec3-2.png"
import img3 from "../../assets/Images/sec3-3.png"
import img4 from "../../assets/Images/sec3-4.png"
import img5 from "../../assets/Images/sec3-5.png"
import img6 from "../../assets/Images/sec3-6.png"
import img7 from "../../assets/Images/sec3-7.png"
import img8 from "../../assets/Images/sec3-8.png"
import img9 from "../../assets/Images/sec3-9.png"



const Section3 = () => {
    const features = [
        {
            icon: <img src={img9} />,
            title: 'Easy online resume builder',
            description: 'Create an awesome resume in minutes, without leaving your web browser.',
        },
        {
            icon: <img src={img1} />,
            title: 'Automatic spell-checker',
            description: 'Our built-in spell-checker takes care of the grammar for you. Create a resume with zero typos or errors.',
        },
        {
            icon: <img src={img5} />,
            title: 'Your data is safe',
            description: 'Your data is kept private and protected by strong 256-bit encryption.',
        },
        {
            icon: <img src={img7} />,
            title: 'Automatic summary generator',
            description: 'Create a powerful resume profile or cover letter in one click. Try for free!',
        },
        {
            icon: <img src={img3} />,
            title: 'Approved templates',
            description: 'Professionally-designed resume templates and examples. Just edit and download in 5 minutes.',
        },
        {
            icon: <img src={img6} />,
            title: 'AI pre-written phrases',
            description: 'Use the power of AI and data analysis, choose pre-generated effective phrases and keywords.',
        },
        {
            icon: <img src={img4} />,
            title: 'Optimized resumes',
            description: 'Formats and designs are optimized for resume-filtering algorithms. Ensure humans see your application!',
        },
        {
            icon: <img src={img2} />,
            title: 'Multi-format resume options',
            description: 'Save your perfect resume in any common format, including Microsoft Word and PDF in a single click.',
        },
        {
            icon: <img src={img8} />,
            title: 'Cover letters',
            description: 'Our cover letter builder works with the same ease and use of elegant templates as the resume creator.',
        },
    ];

    return (
        <div className="features-container">
            <h1>Features designed to help you win your dream job</h1>
            <div className="features-grid">
                {features?.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <div className="sec3-logo-name-flex ">
                            <span className="icon">{feature.icon}</span>
                            <h3>{feature.title}</h3>
                        </div>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default Section3;
