import { useNavigate } from "react-router";
import "../../assets/Styles/Section9.css";

const Section9 = () => {
  const navigate = useNavigate();
  return (
    <div className="resume-section-9">
      <h1>So much more than a resume builder</h1>

      <div className="content-9">
        {/* YouTube Video Section */}
        <div className="video-container-9">
          <iframe
            src="https://www.youtube.com/embed/1rzhcES14mo?start=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Description and Call to Action */}
      <p className="description-9">
        Your job starts with a resume, but what about the interview? When you
        build your resume, you also get access to 18 powerful career tools. It's
        the complete career toolkit, all in one place. If you're here, you're
        already on the way up.
      </p>
      <div className="button-container">
        <button
          className="cta-button-9"
          onClick={() => navigate("/templatecard")}
        >
          Explore your potential
        </button>
      </div>
    </div>
  );
};

export default Section9;
