import * as userAPI from "../endpoints/memberDashboard";

const getDashboardData = async (currentPage) => {
  try {
    const response = await userAPI.get_Dashboard_Data(currentPage);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const handleDeleteResume = async (id) => {
  try {
    const response = await userAPI.delete_Resume(id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const CloneResume = async (id) => {
  try {
    const response = await userAPI.Clone_Resume(id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {getDashboardData,handleDeleteResume,CloneResume};