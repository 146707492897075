import "../../assets/Styles/Footer.css";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { SlSocialSpotify } from "react-icons/sl";
import crpwImg from "../../assets/Images/cprw.webp"


const Footer = () => {
    return (
      <>
        <footer className="footer" role="contentinfo">
          <div className="footer-container">
            <div className="footer-col" role="navigation">
              <h5 className="footer-logo">Connect with us on social media</h5>
              <ul>
                <div className="footer-icons">
                  <li>
                    {" "}
                    <TiSocialLinkedin size={25} />
                  </li>
                  <li>
                    {" "}
                    <AiFillYoutube size={25} />
                  </li>
                  <li>
                    {" "}
                    <FaPinterestP size={25} />{" "}
                  </li>
                  <li>
                    {" "}
                    <IoLogoInstagram size={25} />
                  </li>
                </div>
                <div className="footer-icons mt-2">
                  <li>
                    <FaFacebookF size={25} />
                  </li>

                  <li>
                    <FaTiktok size={25} />
                  </li>
                  <li>
                    <SlSocialSpotify size={25} />
                  </li>
                </div>
              </ul>
            </div>
            <div className="footer-col" role="navigation">
              <h5>Jobseekers</h5>
              <ul>
                <li>
                  <a href="#">Create A Resume</a>
                </li>
                <li>
                  <a href="#">Resume Example</a>
                </li>
                <li>
                  <a href="#">Resume Templates</a>
                </li>
                <li>
                  <a href="#">Cover Letter Templates</a>
                </li>
                <li>
                  <a href="#">Job Search</a>
                </li>
              </ul>
            </div>
            <div className="footer-col" role="navigation">
              <h5>Career Resources</h5>
              <ul>
                <li>
                  <a href="#">Resume Help</a>
                </li>
                <li>
                  <a href="#">Job interview</a>
                </li>
                <li>
                  <a href="#">Career</a>
                </li>
                <li>
                  <a href="#">Cover Letter</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div>
            <div className="footer-col" role="navigation">
              <h5>Our Company</h5>
              <ul>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
                <li>
                  <a href="#">Sponsorship Program</a>
                </li>
                <li>
                  <a href="#">Media Kit</a>
                </li>
                <li>
                  <a href="#">Affiliates</a>
                </li>
              </ul>
            </div>
            <div className="footer-col" role="navigation">
              <h5>Support</h5>
              <ul>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Terms Of Service</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Right of withdrawal</a>
                </li>
              </ul>
            </div>

            {/* <div className="clearfix"></div> */}
            <div className="footer-bottom">
              <div className="footer-left-bottom">
                <span>🇺🇸 International</span>
                <span>Copyright © 2024 - resume.idea2reality</span>
              </div>
              <div className="footer-center-bottom">
                <div className="badge-bottom">
                  <img src={crpwImg} />
                </div>
              </div>
              <div className="footer-right-bottom">
                <span>
                  More than a resume builder. resume.idea2reality is a part of
                </span>
                <span className="brand-bottom">Resume.idea2reality</span>
                <span>product ecosystem.</span>
              </div>
            </div>
          </div>

          {/* <div className="footer-bottom">
                    <p>© 2024 copyright All Right Reserved.</p>

                </div> */}
        </footer>
      </>
    );
}

export default Footer;