import * as actionTypes from "./ActionTypes"; // Import your action types

const initialState = {
    userData:{},
    // You can add more initial user state properties here
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETPROFILEINFO:
            return {
                ...state,
                   userData: {
                    ...state.userData,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default userReducer;
