import { Button, CircularProgress, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../assets/Styles/PreviewComp.css";
import { connect } from "react-redux";
import { templates } from "../Data/templates";
import JsPDF from "jspdf";
import uniqid from "uniqid";
import Modal from "./Modal";
import { createResume, editResume } from "../api/services/resumeService";
import { toast} from 'react-toastify';
import { useNavigate } from "react-router";
import { Alltemplates } from "../Data/AllTemplates";

const mapStateToProps = (state) => ({
  selectedTemplateId: state?.selectedTemplateReducer?.selectedTemplateId,
  selectedResumeId: state?.selectedTemplateReducer?.selectedResumeId,
  personalInfo: state?.personalInfoReducer?.personalInfo,
  experiences: state?.workExpReducer?.experiences,
  educationInfo: state?.EducationInfo?.educationInfo,
  skills: state?.SkillReducer?.skills,
});

const mapDispatchToProps = (dispatch) => ({});

const PreviewComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [resumeName, setResumeName] = useState("");
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [allformValues, setAllFormValues] = useState({});
  const navigate = useNavigate();

  const getTemplate = (template, index) => {
    if (template.id == props?.selectedTemplateId) {
      const TemplateComp = React.cloneElement(template.template, {
        personalInfo: props?.personalInfo,
        experiences: props?.experiences,
        educationInfo: props?.educationInfo,
        skills: props?.skills,
        index: index,
        key: template.id,
      });
      return TemplateComp;
    }
  };

  const base64ToFile= (base64String, fileName = 'image.png')=> {
    // Split the base64 string into metadata and data
    const [metadata, data] = base64String.split(',');
    // console.log("metadata",metadata)
    // Extract the mime type from metadata
   try{
    const mimeType = metadata?.match(/:(.*?);/)[1];
    // Decode the base64 string
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Create a File object
    const file = new File([byteArray], fileName, { type: mimeType });
    return file;
  }catch(err){
    console.log(err)
  }
}


  // console.log("mainProps",props)

  const handleStaticSave=()=>{
        const report = new JsPDF("portrait", "pt", "a4");
      report
          .html(document.getElementById(`${props.selectedTemplateId - 1}report`))
          .then(() => {
              report.save(`${resumeName}.pdf`);
              setLoading(false);
              //Saving the user data in localstorage
              let resumes = window.localStorage.getItem("resumes");

              if (resumes) {
                  let newResumes = JSON.parse(resumes);

                  let resumeFound = newResumes.find(
                      (resume) => resume.id == props.selectedResumeId
                  );

                  if (resumeFound) {
                      const allNewResumes = newResumes?.map((resume) => {
                          if (resume.id == props?.selectedResumeId) {
                              return {
                               template_id: props?.selectedTemplateId,
                               id: props?.selectedResumeId,
                               personalInfo: props?.personalInfo,
                               experiences: props?.experiences,
                               educationInfo: props?.educationInfo,
                               skills: props?.skills,
                              };
                          } else return resume;
                      });

                      window.localStorage.setItem("resumes",JSON.stringify(allNewResumes));
                      window.location.reload();
                      return;
                  }

                  newResumes.push({
                      template_id: props?.selectedTemplateId,
                      id: uniqid(),
                      personalInfo: props?.personalInfo,
                      experiences: props?.experiences,
                      educationInfo: props?.educationInfo,
                      skills: props?.skills,
                  });
                  // for saving the resume to locaal storage
                  window.localStorage.setItem("resumes", JSON.stringify(newResumes));
              } else {
                  window.localStorage.setItem("resumes",
                      JSON.stringify([
                          {
                              template_id: props?.selectedTemplateId,
                              id: uniqid(),
                              personalInfo: props?.personalInfo,
                              experiences: props?.experiences,
                              educationInfo: props?.educationInfo,
                              skills: props?.skills,
                          },
                      ])
                  );
              }

              //Redirect user to the myResumes page
              window.location.reload();
          })
          .catch((error) => console.log(error.message));
    
  }

  const showErrors = (errors) => {
    if (typeof errors == 'object' && errors !== null) {
      Object.keys(errors).forEach((key) => {
        if (Array.isArray(errors[key])) {
          errors[key].forEach((error) => {
            toast.error(error,{
              position: "top-center",
            });
          });
        }
      });
    }
  };

  const handleCreateResume = async (fData) => {
    const base64String = fData?.profileImg;
    const fileName = "image.png"; // Or provide a default name
    const file = await base64ToFile(base64String, fileName);
    const data = {
      first_name: fData?.firstName,
      last_name: fData?.lastName,
      email:fData?.email,
      address:fData?.address,
      country_id: fData?.country_id,
      city_id: fData?.city_id,
      state_id: fData?.state_id,
      postal_code:fData?.postalCode,
      objective:fData?.objective,
      education:fData?.education,
      work_experience:fData?.experiences,
      skills:fData?.skills,
      profile:file,
      template_id:fData?.template_id,
      phone:fData?.phone,
      country_code:fData?.country_code
    };
    
    if(props?.selectedResumeId){
      bgBlur();
      try {
        const response = await editResume(props?.selectedResumeId,data);
        if(response?.success){
          openModal();
          toast.success(response?.message, {
            position: "top-center",
          });
          navigate("/MyResume")
        }else{
          showErrors(response.errors);
        }
      }catch(err){
        console.log(err)
      }
    }else{
    try {
      const response = await createResume(data);
      if(response?.success){
        toast.success(response?.message, {
          position: "top-center",
        });
        navigate("/MyResume")
      }else{
        showErrors(response.errors);
      }
    } catch (error) {
      console.log(error);
    }}
  };

  //console.log("educationInfo",props?.educationInfo) 
  const handleSave = () => {
    if (resumeName?.length == 0) {
      setError("*Please fill this field");
    } else {
      setError("");
      //setLoading(true);
      const educationInfo = props?.educationInfo;
      const experiences = props?.experiences;
      const personalInfo = props?.personalInfo;
      const skills = props?.skills;
      const selectedTemplateId = props?.selectedTemplateId
      
      const mergedData = {
        ...personalInfo,
        education: [...educationInfo],
        experiences: [...experiences],
        skills: [...skills],
        template_id:selectedTemplateId
      };
      handleCreateResume(mergedData);
    }
  };

  const handleBack=()=>{
    if (props.tab > 0) {
      props.setTab(props.tab - 1);
    }
  };

  const openModal = async () => {
    setModal(true);
    setTimeout(() => {
     setModal(false);
    },2000);
  };

  const bgBlur = () => {
    const blur = document.getElementById("main");
    blur.classList.toggle("active");
  };

  return (
    <>
      <Container
        id="main"
        sx={{
          padding: {
            xs: "40px 20px",
            md: "60px 80px",
          },
        }}
        className="preview-container col-12"
      >
        {/* <h2 className="preview-header-title">Resume Preview</h2> */}
        <div className="resume-preview-grid-container">
          <div className="resume-preview-grid-item">
            <div className="resume-save-container">
              <h3 className="resume-save-title">Create File Name</h3>
              <div style={{ display: "flex", gap: "10px", alignItems:"baseline" }}>
                <TextField
                  value={resumeName}
                  onChange={(e) => setResumeName(e.target.value)}
                  className="resume-name-field"
                  sx={{ width: "70%" }}
                  id="outlined-basic"
                  variant="outlined"
                  error={error.length > 0 ? true : false}
                  helperText={error}
                  placeholder="File Name"
                />
                <div className="resume-back-next-container">
                  <Button
                    onClick={handleBack}
                    className="outlined-btn"
                    sx={{ marginRight: "20px" }}
                    variant="outlined">
                    Back
                  </Button>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Button
                      onClick={() => {
                        handleSave();
                      }}
                      className="contained-btn"
                      variant="contained">
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="resume-preview-grid-item" id="previewresume">
            {Alltemplates?.map((template, index) => {
              return getTemplate(template, index);
            })}
          </div>
        </div>
      </Container>
      {modal === true ? <Modal /> : null}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewComponent);
