import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const ForgotWithMobile = ({mobileForm,setPhoneOtp, setMobileForm,setOtpSection}) => {
  const [errors, setErrors] = useState({});
  const [btnLoading,setBtnLoading]=useState(false)

  const onLoginWithMobile = async(e) => {
    e.preventDefault();
    if (validateForm()) {
       setOtpSection(true);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      dialingCode: '',
      mobileNumber: '',
    };

    if (!mobileForm.dialingcode) {
      newErrors.dialingCode = 'Dialing code is required';
      valid = false;
    } else if (isNaN(mobileForm.dialingcode)) {
      newErrors.dialingCode = 'Invalid dialing code, should contain only numbers';
      valid = false;
    }

    if (!mobileForm.mobilenumber) {
      newErrors.mobileNumber = 'Mobile number is required';
      valid = false;
    } else if (isNaN(mobileForm.mobilenumber)) {
      newErrors.mobileNumber = 'Invalid mobile number, should contain only numbers';
      valid = false;
    } else if (mobileForm.mobilenumber.toString().length < 10) {
      newErrors.mobileNumber = 'Mobile number should be at least 10 digits';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOnChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setMobileForm({
      dialingcode: d_code,
      mobilenumber: parseInt(phoneNum),
    });
  };
  return (
    <>
      <form className="mt-2 p-0" onSubmit={onLoginWithMobile}>
        <>
          <div className="form-group">
            <PhoneInput inputClass="custom-phone-input" country={"in"} onChange={handleOnChange} />
            {errors?.mobileNumber && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.mobileNumber}
              </span>
            )}
          </div>
        </>
        <div className="d-flex flex-column">
          <button type="submit" className="login-signup-btn mt-4">
            {
             btnLoading &&
              <div className="spinner-border text-light" role="status">
               <span className="sr-only">Loading...</span>
              </div>
            }
            Submit
          </button>
          <Link to="/login" className="register-btn mt-4">
            Back To Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotWithMobile;
