import React, {useState,useEffect} from "react";
import { getCountryStatesCity } from "../../../../api/services/userService";


const AddressSection = ({ formData, setFormData, errors }) => {
    const [countries, seCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [zip_country, setZip_Country] = useState({});
    const [zip_state, setZip_State] = useState({});
    const [zip_city, setZip_City] = useState({});


  const handlePinCodeChange = async (val, type) => {
    const pinc = val;
    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      return;
    }
    setFormData((prevInput) => ({ ...prevInput, postal_code: pinc }));
    // try {
    //   const response = await fetch(
    //     `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`);
    //   const data = await response.json();
    //   if (data.results && data.results.length > 0) {
    //     const addressComponents = data.results[0].address_components;
    //     const latLong = data?.results[0]?.geometry?.location;
    //     const fullAddress = addressComponents
    //       ?.map((component) => component.long_name)
    //       .join(", ");

    //     if (addressComponents) {
    //       const cityComponent = addressComponents.find((component) =>
    //         component?.types?.includes("locality")
    //       );
    //       const stateComponent = addressComponents.find((component) =>
    //         component?.types?.includes("administrative_area_level_1")
    //       );
    //       const countryComponent = addressComponents.find((component) =>
    //         component?.types?.includes("country")
    //       );

    //       if (countryComponent) setZip_Country(countryComponent);
    //       if (stateComponent) setZip_State(stateComponent);
    //       if (cityComponent) setZip_City(cityComponent);

    //       const country = countries.find(
    //         (country) => country.country_name == countryComponent?.long_name
    //       );
    //       setStates(country ? country?.states : []);

    //       const state = country
    //         ? country.states.find(
    //             (state) => state?.state_name == stateComponent?.long_name
    //           )
    //         : null;

    //       const city = state
    //         ? state.cities.find(
    //             (city) => city?.city_name == cityComponent?.long_name
    //           )
    //         : null;

    //       if (state) setCities(state.cities);
    //       // console.log("latLong",latLong)
    //       if (addressComponents && latLong) {
    //         if (type == "map") {
    //           setFormData((prevInput) => ({
    //             ...prevInput,
    //             country_id: country?.country_id,
    //             state_id: state?.state_id,
    //             city_id: city?.city_id,
    //           }));
    //         } else {
    //           setFormData((prevInput) => ({
    //             ...prevInput,
    //             country_id: country?.country_id,
    //             state_id: state?.state_id,
    //             city_id: city?.city_id,
    //           }));
    //         }
    //       }
    //     } else {
    //       console.error(
    //         "No address components found in the Geocoding API response."
    //       );
    //     }
    //   } else {
    //     console.error("No results found in the Geocoding API response.");
    //   }
    // } catch (error) {
    //   console.error("Error fetching location:", error);
    // }
  };

  const EditAddress = (cntry, cntryid, stateid, cityid) => {
    // const country = cntry?.find((country) => country?.country_name == "India");
    // setStates(country ? country?.states : []);
    // setCities([]);
    const country = cntry?.find((country) => country?.country_id == cntryid);
    setStates(country ? country?.states : []);

    if (states) {
      const country = cntry.find((country) => country?.country_id == cntryid);
      const state = country
        ? country.states.find((state) => state?.state_id == stateid)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const DefaulStateAddress = (cntry) => {
    const country = cntry?.find((country) => country?.country_name == "India");
    setStates(country ? country?.states : []);
    setCities([]);
  };

  const getCountryCityState = async () => {
    try {
      await getCountryStatesCity().then((response) => {
        seCountries(response?.data?.data?.countries);

        EditAddress(
          response?.data?.data?.countries,
          formData?.country_id,
          formData?.state_id,
          formData?.city_id
        )
   
          // DefaulStateAddress(response?.data?.data?.countries);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryCityState();
  }, [formData?.country_id]);

  const handleCountryChange = (e) => {
    const cntry = e?.target?.value;
    setFormData({
      ...formData,
      country_id:cntry,
      // state_id: "",
      // city_id: "",
    });
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      const country = countries.find((country) => country.country_id == "101");
      setStates(country ? country?.states : []);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setFormData({ ...formData, state: sState, state_id: sState, city: "" });

    if (sState) {
      const state = states
        ? states.find((state) => state?.state_id == sState)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    setFormData({ ...formData, city_id: cityid });
  };

  return (
    <>
    <div className="d-flex gap-3 mt-3">
      <div className="form-group w-100">
        <label className="radio-btn-label">
          Pin Code<span className="astrik-icon">*</span>
        </label>
        <input
          type="number"
          min="0"
          value={formData?.postal_code}
          name="postal_code"
          onChange={(e) => handlePinCodeChange(e.target.value)}
          className="form-control"
          placeholder="Pin Code"
          autocomplete="false"
        />
        {errors?.postal_code && <small className="text-danger">{errors.postal_code}</small>}
      </div>

      <div className="form-group w-100">
        <label className="radio-btn-label">
          Country<span className="astrik-icon">*</span>
        </label>
        <select
          className="form-control"
          name="country_id"
          onChange={handleCountryChange}>
          <option selected disabled>
            Select Country
          </option>
          {countries &&
            countries?.map((val, index) => {
              return (
                <option
                  key={index}
                  value={val?.country_id}
                  selected={
                    Object?.keys(zip_country)?.length == 0
                      ? formData?.country_id
                        ? val?.country_id == formData?.country_id
                        : val?.country_name == "India"
                      : zip_country?.long_name == val?.country_name
                  }
                >
                  {val?.country_name}
                </option>
              );
            })}
        </select>
        {errors.country_id && (
         <small className="text-danger">{errors.country_id}</small>
        )}
      </div>
      <div className="form-group w-100">
        <label className="radio-btn-label">
          State<span className="astrik-icon">*</span>
        </label>
        <select
          className="form-control"
          name="state_id"
          onChange={handleStateChange}>
          <option selected disabled>
            Select State
          </option>
          {states &&
            states?.map((val, index) => {
              return (
                <option
                  key={index}
                  value={val?.state_id}
                  selected={
                    Object.keys(zip_state).length == 0
                      ? val?.state_id == formData?.state_id
                      : zip_state?.long_name == val?.state_name
                  }>
                  {val?.state_name}
                </option>
              );
            })}
        </select>
        {errors.state_id && <small className="text-danger">{errors.state_id}</small>}
      </div>

      <div className="form-group w-100">
        <label className="radio-btn-label">
          City<span className="astrik-icon">*</span>
        </label>
        <select
          className="form-control"
          name="city_id"
          onChange={handleCityChange}>
          <option selected disabled>
            Select City
          </option>
          {cities &&
            cities?.map((val, index) => {
              return (
                <>
                  <option
                    key={index}
                    value={val?.city_id}
                    selected={
                      Object.keys(zip_city).length == 0
                        ? val?.city_id == formData?.city_id
                        : zip_city?.long_name == val?.city_name
                    }>
                    {val?.city_name}
                  </option>
                </>
              );
            })}
        </select>
        {errors.city_id && <small className="text-danger">{errors.city_id}</small>}
      </div>
      </div>

      <div className="d-flex gap-3 mt-3">
      </div>
    </>
  );
};

export default AddressSection;
