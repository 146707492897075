import "../../assets/Styles/Section11.css"
import blankImg from "../../assets/Images/blank-img.png"
import { useNavigate } from "react-router";

const Section11 = () => {
    const navigate = useNavigate();


    const createResume = () => {
        navigate('/templatecard')
    }


    return (
      <>
        <div className="sec11-background-container">
          <div className="sec11-falling-image">
            <img src={blankImg} alt="Falling Image" />
          </div>
          <div className="sec11-falling-image1">
            <img src={blankImg} alt="Falling Image" />
          </div>
          <div className="sec11-falling-image2">
            <img src={blankImg} alt="Falling Image" />
          </div>

          <div className="sec11-falling-image3">
            <img src={blankImg} alt="Falling Image" />
          </div>

          <div className="sec11-falling-image4">
            <img src={blankImg} alt="Falling Image" />
          </div>

          <div className="sec11-falling-image5">
            <img src={blankImg} alt="Falling Image" />
          </div>

          <div className="sec11-overlay-text">
            <h1>
              User-friendly. Professional. Effective. Try our cover letter
              builder today!
            </h1>

            <p>
              How long does it take to write a cover letter? Hours? Days? With
              our cover letter maker you can be done in minutes. Create a
              convincing and effective application letter in several clicks. Use
              our pre-generated phrases, choose a design, fill in your details
              and ideas. Fast and simple.
            </p>
            <div className="home-letters__buttons gap-2">
              <button className="home-letter-btn1" onClick={createResume}>
                Try it now
              </button>
              <button className="home-letter-btn2" onClick={createResume}>
                Cover letter examples
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default Section11;