// import React, { useState } from 'react';
// import { Divider, MenuItem, Paper, Select } from "@mui/material";
// import { connect } from "react-redux";
// import { ADDEDUCATION } from "../Redux/Actions";
// import { useForm, Controller } from "react-hook-form";
// import "../assets/Styles/EducationComp.css";
// import BackNextBtnComp from "./BackNextBtnComp";
// import InputComp from './InputComp';
// import SelectComp from "./SelectComp";

// const Years = [
//     "2024",
//     "2023",
//     "2022",
//     "2021",
//     "2020",
//     "2019",
//     "2018",
//     "2017",
//     "2016",
//     "2015",
//     "2014",

// ];

// const mapStateToProp = (state) => ({
//     educationInfo: state.EducationInfo.educationInfo,
// })

// const mapDispatchToProps = (dispatch) => ({
//     onAddEducation: (details) => dispatch(ADDEDUCATION(details))
// })

// const EducationComp = (props) => {
//     const [loading, setLoading] = useState(false);
//     const {
//         register,
//         handleSubmit,
//         control,
//         formState: { errors },
//     } = useForm();

//     const handleNext = (data) => {
//         setLoading(true);
//         props.onAddEducation(data);

//         setTimeout(() => {
//             setLoading(false);
//             props.setTab(props.tab + 1);
//         }, 1000);
//     }

//     const handleBack = () => {
//         props.setTab(props.tab - 1);
//     }

//     return (
//         <Paper className="EducationPaper col-9" elevation={3}>
//             <h2 className="education-heading">Education Details</h2>
//             <Divider sx={{ margin: "10px 0px" }} />
//             <form onSubmit={handleSubmit(handleNext)}>
//                 <div className="education-form">
//                     <InputComp title={"Domain"}
//                         type={"text"}
//                         name={"domain"}
//                         placeholder={"Domain"}
//                         register={register}
//                         multiline={false}
//                         value={props.educationInfo.domain}
//                         setValue={(value) =>
//                             props.onAddEducation({ ...props.educationInfo, domain: value })}
//                         error={errors.domain ? true : false}
//                         errorMessage={errors.domain ? errors.domain.message : null}
//                     />
//                     <div></div>
//                     <InputComp
//                         title={"University"}
//                         type={"text"}
//                         name={"university"}
//                         placeholder={"University"}
//                         register={register}
//                         multiline={false}
//                         value={props.educationInfo.university}
//                         setValue={(value) =>
//                             props.onAddEducation({
//                                 ...props.educationInfo,
//                                 university: value,
//                             })
//                         }
//                         error={errors.university ? true : false}
//                         errorMessage={errors.university ? errors.university.message : null}
//                     />

//                     <InputComp
//                         title={"Degree"}
//                         type={"text"}
//                         name={"degree"}
//                         placeholder={"Degree"}
//                         register={register}
//                         multiline={false}
//                         value={props.educationInfo.degree}
//                         setValue={(value) =>
//                             props.onAddEducation({ ...props.educationInfo, degree: value })
//                         }
//                         error={errors.degree ? true : false}
//                         errorMessage={errors.degree ? errors.degree.message : null}
//                     />

//                     <SelectComp
//                         title={"Start Year"}
//                         errorMessage={errors.startYear ? errors.startYear.message : null}
//                         error={errors.startYear ? true : false}>
//                         <Controller
//                             render={(props) => {
//                                 return (
//                                     <Select
//                                         value={props.field.value}
//                                         onChange={props.field.onChange}
//                                         error={errors.startYear ? true : false}>
//                                         <MenuItem value="" disabled>
//                                             Select a start year
//                                         </MenuItem>
//                                         {Years.map((Year, index) => {
//                                             return (
//                                                 <MenuItem key={index} value={Year}>
//                                                     {Year}
//                                                 </MenuItem>
//                                             );
//                                         })}
//                                     </Select>
//                                 );
//                             }}
//                             name={"startYear"}
//                             control={control}
//                             rules={{ required: "*Please Select Start Year" }}
//                             defaultValue={props.educationInfo.startYear}
//                         />
//                     </SelectComp>

//                     <SelectComp
//                         title={"End Year"}
//                         errorMessage={errors.endYear ? errors.endYear.message : null}
//                         error={errors.endYear ? true : false}>
//                         <Controller
//                             render={(props) => (
//                                 <Select
//                                     value={props.field.value}
//                                     onChange={props.field.onChange}
//                                     error={errors.endYear ? true : false}>
//                                          <MenuItem value="" disabled>
//                                             Select a end year
//                                         </MenuItem>
//                                     {Years.map((Year, index) => {
//                                         return (
//                                             <MenuItem key={index} value={Year}>
//                                                 {Year}
//                                             </MenuItem>
//                                         );
//                                     })}
//                                 </Select>
//                             )}
//                             name={"endYear"}
//                             control={control}
//                             rules={{ required: "*Please Select End Year" }}
//                             defaultValue={props.educationInfo.endYear}
//                         />
//                     </SelectComp>
//                 </div>
//                 <Divider sx={{ margin: "10px 0px" }} />
//                 <BackNextBtnComp
//                     onNext={handleNext}
//                     onBack={handleBack}
//                     loading={loading}
//                     tab={props.tab}
//                     nextTitle={"Next"}
//                     backTitle={"Back"}
//                 />
//             </form>

//         </Paper>
//     )
// }

// export default connect(mapStateToProp, mapDispatchToProps)(EducationComp)

// import React, { useState } from 'react';
// import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
// import { connect } from "react-redux";
// import { ADDEDUCATION } from "../Redux/Actions";
// import { useForm, Controller } from "react-hook-form";
// import "../assets/Styles/EducationComp.css";
// import BackNextBtnComp from "./BackNextBtnComp";
// import InputComp from './InputComp';
// import SelectComp from "./SelectComp";

// const Years = [
//     "2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014",
// ];

// const mapStateToProp = (state) => ({
//     educationInfo: state.EducationInfo.educationInfo,
// });

// const mapDispatchToProps = (dispatch) => ({
//     onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
// });

// const EducationComp = (props) => {
//     const [loading, setLoading] = useState(false);
//     const [educationList, setEducationList] = useState([{ domain: '', university: '', degree: '', startYear: '', endYear: '' }]);

//     const {
//         register,
//         handleSubmit,
//         control,
//         formState: { errors },
//         getValues,
//         setValue,
//     } = useForm();

//     const handleNext = (data) => {
//         setLoading(true);
//         props.onAddEducation(educationList);

//         setTimeout(() => {
//             setLoading(false);
//             props.setTab(props.tab + 1); // Navigate to the next tab
//         }, 1000);
//     };

//     const handleBack = () => {
//         props.setTab(props.tab - 1);
//     };

//     // Handle adding a new education entry
//     const addNewEducation = () => {
//         setEducationList([...educationList, { domain: '', university: '', degree: '', startYear: '', endYear: '' }]);
//     };

//     // Handle changes to individual education entry
//     const updateEducation = (index, key, value) => {
//         const updatedList = educationList.map((education, i) =>
//             i === index ? { ...education, [key]: value } : education
//         );
//         setEducationList(updatedList);
//     };

//     return (
//         <Paper className="EducationPaper col-9" elevation={3}>
//             <h2 className="education-heading">Education Details</h2>
//             <Divider sx={{ margin: "10px 0px" }} />
//             <form onSubmit={handleSubmit(handleNext)}>
//                 {educationList.map((education, index) => (
//                     <div key={index} className="education-form">
//                         <h4>Education {index + 1}</h4>
//                         <InputComp
//                             title={"Domain"}
//                             type={"text"}
//                             name={`domain-${index}`}
//                             placeholder={"Domain"}
//                             register={register}
//                             multiline={false}
//                             value={education.domain}
//                             setValue={(value) => updateEducation(index, 'domain', value)}
//                             error={errors[`domain-${index}`] ? true : false}
//                             errorMessage={errors[`domain-${index}`] ? errors[`domain-${index}`].message : null}
//                         />
//                         <InputComp
//                             title={"University"}
//                             type={"text"}
//                             name={`university-${index}`}
//                             placeholder={"University"}
//                             register={register}
//                             multiline={false}
//                             value={education.university}
//                             setValue={(value) => updateEducation(index, 'university', value)}
//                             error={errors[`university-${index}`] ? true : false}
//                             errorMessage={errors[`university-${index}`] ? errors[`university-${index}`].message : null}
//                         />
//                         <InputComp
//                             title={"Degree"}
//                             type={"text"}
//                             name={`degree-${index}`}
//                             placeholder={"Degree"}
//                             register={register}
//                             multiline={false}
//                             value={education.degree}
//                             setValue={(value) => updateEducation(index, 'degree', value)}
//                             error={errors[`degree-${index}`] ? true : false}
//                             errorMessage={errors[`degree-${index}`] ? errors[`degree-${index}`].message : null}
//                         />
//                         <SelectComp
//                             title={"Start Year"}
//                             errorMessage={errors[`startYear-${index}`] ? errors[`startYear-${index}`].message : null}
//                             error={errors[`startYear-${index}`] ? true : false}
//                         >
//                             <Controller
//                                 name={`startYear-${index}`}
//                                 control={control}
//                                 rules={{
//                                     required: "*Please Select Start Year",
//                                     validate: (value) =>
//                                         !value || value <= getValues(`endYear-${index}`) || "*Start year must be before or the same as end year",
//                                 }}
//                                 defaultValue={education.startYear}
//                                 render={({ field }) => (
//                                     <Select
//                                         {...field}
//                                         value={education.startYear}
//                                         onChange={(e) => {
//                                             updateEducation(index, 'startYear', e.target.value);
//                                             field.onChange(e);
//                                         }}
//                                         error={!!errors[`startYear-${index}`]}
//                                     >
//                                         <MenuItem value="" disabled>Select a start year</MenuItem>
//                                         {Years.map((Year, idx) => (
//                                             <MenuItem key={idx} value={Year}>{Year}</MenuItem>
//                                         ))}
//                                     </Select>
//                                 )}
//                             />
//                         </SelectComp>
//                         <SelectComp
//                             title={"End Year"}
//                             errorMessage={errors[`endYear-${index}`] ? errors[`endYear-${index}`].message : null}
//                             error={errors[`endYear-${index}`] ? true : false}
//                         >
//                             <Controller
//                                 name={`endYear-${index}`}
//                                 control={control}
//                                 rules={{
//                                     required: "*Please Select End Year",
//                                     validate: (value) =>
//                                         !value || value >= getValues(`startYear-${index}`) || "*End year must be after or the same as start year",
//                                 }}
//                                 defaultValue={education.endYear}
//                                 render={({ field }) => (
//                                     <Select
//                                         {...field}
//                                         value={education.endYear}
//                                         onChange={(e) => {
//                                             updateEducation(index, 'endYear', e.target.value);
//                                             field.onChange(e);
//                                         }}
//                                         error={!!errors[`endYear-${index}`]}
//                                     >
//                                         <MenuItem value="" disabled>Select an end year</MenuItem>
//                                         {Years.map((Year, idx) => (
//                                             <MenuItem key={idx} value={Year}>{Year}</MenuItem>
//                                         ))}
//                                     </Select>
//                                 )}
//                             />
//                         </SelectComp>
//                         <Divider sx={{ margin: "10px 0px" }} />
//                     </div>
//                 ))}
//                 <Button
//                     variant="text"
//                     className="add-new-btn-cont"
//                     color="primary"
//                     onClick={addNewEducation}
//                     style={{ marginBottom: "20px" }}>
//                     Add New Education
//                 </Button>
//                 <BackNextBtnComp
//                     onNext={handleSubmit(handleNext)}
//                     onBack={handleBack}
//                     loading={loading}
//                     tab={props.tab}
//                     nextTitle={"Next"}
//                     backTitle={"Back"}
//                 />
//             </form>
//         </Paper>
//     );
// };

// export default connect(mapStateToProp, mapDispatchToProps)(EducationComp);

import React, { useState, useEffect } from "react";
import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
import { connect } from "react-redux";
import { ADDEDUCATION } from "../Redux/Actions";
import { useForm, Controller } from "react-hook-form";
import "../assets/Styles/EducationComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import InputComp from "./InputComp";
import SelectComp from "./SelectComp";

const Years = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
];

const mapStateToProp = (state) => ({
  educationInfo: state.EducationInfo.educationInfo, // Saved education data from Redux
});

const mapDispatchToProps = (dispatch) => ({
  onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
});

const EducationComp = (props) => {
  const [loading, setLoading] = useState(false);
  const [educationList, setEducationList] = useState([
    { domain: "", university: "", degree: "", start_year: "", end_year: "" },
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  // Initialize educationList with saved data from Redux (if available)
  useEffect(() => {
    if (props.educationInfo && props.educationInfo.length > 0) {
      setEducationList(props.educationInfo); // Set saved education data
    }
  }, [props.educationInfo]);

  const handleNext = (data) => {
    setLoading(true);
    props.onAddEducation(educationList);

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1); // Navigate to the next tab
    }, 1000);
  };

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  // Handle adding a new education entry
  const addNewEducation = () => {
    setEducationList([
      ...educationList,
      { domain: "", university: "", degree: "", start_year: "", end_year: "" },
    ]);
  };

  // Handle changes to individual education entry
  const updateEducation = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };

  return (
    <Paper className="EducationPaper col-9" elevation={3}>
      <h2 className="education-heading">Education Details</h2>
      <Divider sx={{ margin: "10px 0px" }} />
      <form onSubmit={handleSubmit(handleNext)}>
        {educationList.map((education, index) => (
          <div key={index} className="education-form">
            <h4>Education {index + 1}</h4>
            <InputComp
              title={"Domain"}
              type={"text"}
              asterisk={true}
              name={`domain-${index}`}
              placeholder={"Domain"}
              register={register}
              multiline={false}
              value={education.domain}
              setValue={(value) => updateEducation(index, "domain", value)}
              error={errors[`domain-${index}`] ? true : false}
              errorMessage={
                errors[`domain-${index}`]
                  ? errors[`domain-${index}`].message
                  : null
              }
            />
            <InputComp
              title={"University"}
              type={"text"}
              asterisk={true}
              name={`university-${index}`}
              placeholder={"University"}
              register={register}
              multiline={false}
              value={education.university}
              setValue={(value) => updateEducation(index, "university", value)}
              error={errors[`university-${index}`] ? true : false}
              errorMessage={
                errors[`university-${index}`]
                  ? errors[`university-${index}`].message
                  : null
              }
            />
            <InputComp
              title={"Degree"}
              type={"text"}
              asterisk={true}
              name={`degree-${index}`}
              placeholder={"Degree"}
              register={register}
              multiline={false}
              value={education.degree}
              setValue={(value) => updateEducation(index, "degree", value)}
              error={errors[`degree-${index}`] ? true : false}
              errorMessage={
                errors[`degree-${index}`]
                  ? errors[`degree-${index}`].message
                  : null
              }
            />
            <SelectComp
              title={"Start Year"}
              asterisk={true}
              errorMessage={
                errors[`start_year-${index}`]
                  ? errors[`start_year-${index}`].message
                  : null
              }
              error={errors[`start_year-${index}`] ? true : false}
            >
              <Controller
                name={`start_year-${index}`}
                control={control}
                rules={{
                  required: "*Please Select Start Year",
                  validate: (value) =>
                    !value ||
                    value <= getValues(`end_year-${index}`) ||
                    "*Start year must be before or the same as end year",
                }}
                defaultValue={education.start_year}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={education.start_year}
                    onChange={(e) => {
                      updateEducation(index, "start_year", e.target.value);
                      field.onChange(e);
                    }}
                    error={!!errors[`start_year-${index}`]}>
                    <MenuItem value="" disabled>
                      Select a start year
                    </MenuItem>
                    {Years.map((Year, idx) => (
                      <MenuItem key={idx} value={Year}>
                        {Year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </SelectComp>
            <SelectComp
              title={"End Year"}
              asterisk={true}
              errorMessage={
                errors[`end_year-${index}`]
                  ? errors[`end_year-${index}`].message
                  : null
              }
              error={errors[`end_year-${index}`] ? true : false}>
              <Controller
                name={`end_year-${index}`}
                control={control}
                rules={{
                  required: "*Please Select End Year",
                  validate: (value) =>
                    !value ||
                    value >= getValues(`start_year-${index}`) ||
                    "*End year must be after or the same as start year",
                }}
                defaultValue={education.end_year}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={education.end_year}
                    onChange={(e) => {
                      updateEducation(index, "end_year", e.target.value);
                      field.onChange(e);
                    }}
                    error={!!errors[`end_year-${index}`]}
                  >
                    <MenuItem value="" disabled>
                      Select an end year
                    </MenuItem>
                    {Years.map((Year, idx) => (
                      <MenuItem key={idx} value={Year}>
                        {Year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </SelectComp>
            <Divider sx={{ margin: "10px 0px" }} />
          </div>
        ))}
        <Button
          variant="text"
          className="add-new-btn-cont"
          color="primary"
          onClick={addNewEducation}
          style={{ marginBottom: "20px" }}
        >
          Add New Education
        </Button>
        <BackNextBtnComp
          onNext={handleSubmit(handleNext)}
          onBack={handleBack}
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form>
    </Paper>
  );
};

export default connect(mapStateToProp, mapDispatchToProps)(EducationComp);
