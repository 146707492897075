// import "../../assets/Styles/Section5.css";
// import Temp1 from "../../assets/Images/temp-1.jpg"
// import Temp2 from "../../assets/Images/temp-2.png"
// import Temp3 from "../../assets/Images/temp-3.png"
// import { useNavigate } from "react-router";

// const Section5 = () => {
//     const navigate = useNavigate();

//     const createResume = () => {
//       navigate('/templatecard')
//     }

//     return (
//         <>
//             <div className="resume-builder-container-top">
//                 <div className="resume-builder-container">
//                     <div className="resume-preview-section">
//                         <img
//                             src={Temp1}
//                             alt="Resume 1"
//                             className="resume-image"
//                         />
//                         <img
//                             src={Temp2}
//                             alt="Resume 2"
//                             className="resume-image"
//                         />
//                         <img
//                             src={Temp3}
//                             alt="Resume 3"
//                             className="resume-image"
//                         />
//                     </div>

//                     <div className="resume-info-section">
//                         <h1>Try our professional Resume builder now!</h1>
//                         <p>
//                             Save time with our easy 3-step resume builder. No more writer’s block or
//                             formatting difficulties in Word. Rapidly make a perfect resume employers love.
//                         </p>
//                         <div className="buttons">
//                             <button className="create-resume-btn" onClick={createResume}>Create my resume</button>
//                             <button className="resume-examples-btn">Resume examples</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
// export default Section5


import "../../assets/Styles/Section5.css"
import Temp1 from "../../assets/Images/temp-1.jpg"
import Temp2 from "../../assets/Images/temp-2.png"
import Temp3 from "../../assets/Images/temp-3.png"
import { useNavigate } from "react-router"

const Section5 = () => {
    const navigate = useNavigate();


    const createResume = () => {
        navigate('/templatecard')
    }

    return (
      <>
        <div className="app-sec56">
          <div className="content56">
            <div className="resume-section56">
              <img src={Temp1} alt="Resume 1" className="resume56 resume56-1" />
              <img
                src={Temp3}
                alt="Resume56 2"
                className="resume56 resume56-2"
              />
              <img
                src={Temp2}
                alt="Resume56 3"
                className="resume56 resume56-3"
              />
            </div>

            <div className="text-section56">
              <h1>Try our professional Resume builder now!</h1>
              <p>
                Save time with our easy 3-step resume builder. No more writer’s
                block or formatting difficulties in Word. Rapidly make a perfect
                resume employers love.
              </p>
              <div className="buttons56">
                <button className="primary-button56" onClick={createResume}>
                  Get started now
                </button>
                <button className="secondary-button56-2" onClick={createResume}>
                  Resume examples
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default Section5;