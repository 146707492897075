
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import Usa from "assets/img/dashboards/usa.png";
import Usa from "../../../assets/img/dashboards/usa.png"
// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniCalendar from "../../../components_sec/calendar/MiniCalendar";
import "../../../assets/css/Default.css";
import MiniStatistics from "../../../components_sec/card/MiniStatistics";
import IconBox from "../../../components_sec/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import ComplexTable from "../../../views/admin/default/components/ComplexTable";
import {columnsDataComplex} from "../../../views/admin/default/variables/columnsData";
import tableDataComplex from "../../../views/admin/default/variables/tableDataComplex.json";
import { FaEye } from "react-icons/fa";
import { getDashboardData } from "../../../api/services/memberDashboardService";
export default function UserReports() {
  const [dashboardData,setDashboardData]=useState({});
  const [tableData,setTableData]=useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading]=useState(false);
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

   const getDashboard_Data=async()=>{
    setLoading(true)
    try{
      const response =await getDashboardData(currentPage);
      const data = response?.data
      setDashboardData(data);
      setTableData(data?.resume?.data);
    }catch(err){
      console.log(err)
    }finally{
      setLoading(false)
    }
  }

  // console.log("td",tableData)

  useEffect(()=>{
    getDashboard_Data();
  },[currentPage]);

  console.log("dashboardData",dashboardData?.resume?.data)
  return (
    <Box pt={{ base:"130px", md: "80px", xl: "80px" }} height="400px">
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap='20px'
        mb='20px'>

        <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}/>  
          }
          name='Total Resume'
          value={dashboardData?.total_resumes}
        />

        {/* <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={FaEye} color={brandColor} />}/>
          }
          name='Total Views Resume'
          value='2349'
        /> */}
        
        <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}/>
          }
          name='List Of My Resume'
          value={dashboardData?.my_resumes}
        />

      </SimpleGrid>
      
      {
       dashboardData?.resume?.data?.length > 0 &&
       <ComplexTable
        // columnsData={columnsDataComplex}
        tableData={dashboardData?.resume}
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        loading={loading}
      />
      }
    </Box>
  );
}
