import axios from "axios";
import API_CONFIG from "../apiConfig";
import axiosInstance from "../axiosInstance";

const loginUser = (fData) => {
  return axios.post(`${API_CONFIG.baseURL}auth/login`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const registerUser = (fData) => {
  return axios.post(`${API_CONFIG?.baseURL}auth/register`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const email_Forgot_Password = (fData) => {
  return axios.post(`${API_CONFIG?.baseURL}auth/email_forget_password`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const forget_Verify_Otp = (fData) => {
  return axios.post(`${API_CONFIG?.baseURL}auth/forget_verify_otp`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const forgot_Password = (fData) => {
  return axios.post(`${API_CONFIG?.baseURL}auth/forget_password`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const get_User_Info = () => {
  return axiosInstance.get(`${API_CONFIG?.baseURL}user/profile_info`);
};

const update_Profile = (fData) => {
  return axiosInstance.post(`${API_CONFIG?.baseURL}user/update_profile`,fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getcountry_States_City=()=>{
  return axiosInstance.get(`${API_CONFIG?.baseURL}getcountryStatesCity`);
}

const get_Edit_Profile=()=>{
  return axiosInstance.get(`${API_CONFIG?.baseURL}user/get_edit_profile`);
}

const getProfile_DropdownVal=()=>{
  return axiosInstance.get(`${API_CONFIG?.baseURL}dropdown`);
}


export {
  loginUser,
  registerUser,
  email_Forgot_Password,
  forget_Verify_Otp,
  forgot_Password,
  get_User_Info,
  update_Profile,
  getcountry_States_City,
  get_Edit_Profile,
  getProfile_DropdownVal
};
