import "../../assets/Styles/SpotifySwiperTemplate.css"
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../../assets/Styles/VideoSwiperTemplate.css"
import spotifyImage from "../../assets/Images/Cover_Spotify.avif"
import { Navigation, Pagination } from 'swiper/modules';

const VideoSwiperTemplate = () => {

    const videos = [
        {
            id: 1,
            spotifyUrl: "https://open.spotify.com/episode/2ylJBS9GPPX3dnbL7Cc6DE?si=tzmEYH0GSW6pBSIO94esBA&nd=1&dlsi=6ace3200495f4cff",
            title: "The Roadmap Episode 4 - The secret of writing a cover letter with no experience",
            tags: ["Audio", "Cover Letter"]
        },
        {
            id: 2,
            spotifyUrl: "https://open.spotify.com/episode/2ylJBS9GPPX3dnbL7Cc6DE?si=tzmEYH0GSW6pBSIO94esBA&nd=1&dlsi=34b29fff0e964c92",
            title: "The Roadmap Episode 7 - Internship and advice for students",
            tags: ["Audio", "Career"]
        },
        {
            id: 3,
            spotifyUrl: "https://open.spotify.com/episode/2ylJBS9GPPX3dnbL7Cc6DE?si=tzmEYH0GSW6pBSIO94esBA&nd=1&dlsi=a2e9d8dc4aaf4f4a",
            title: "The Roadmap Episode 6 - Handling love in the workplace",
            tags: ["Audio", "Career"]
        },
        {
            id: 4,
            spotifyUrl: "https://open.spotify.com/episode/2ylJBS9GPPX3dnbL7Cc6DE?si=tzmEYH0GSW6pBSIO94esBA&nd=1&dlsi=34b29fff0e964c92",
            title: "The Roadmap Episode 3 - Writing a resignation letter that gets the job done",
            tags: ["Audio", "Career"]
        }
    ];



    const spotifyUrlOpen = (url) => {
        window.open(url);
    };

    return (
        <div className="spotify-expert-videos">
            <div className="spotify-header">
                <h2>Carrer podcasts</h2>

                <div className="spotify-header-buttons">
                    <button className="spotify-all-videos-btn">All podcasts</button>
                </div>
            </div>

            <Swiper
                slidesPerView={3}
                spaceBetween={20}
                navigation={true}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                className="spotify-mySwiper"
            >

                {videos?.map(video => (
                    <SwiperSlide key={video.id}>
                        <div className="spotify-video-card">
                            <img
                                srcset={spotifyImage}
                                alt={video.title}
                                className="spotify-video-thumbnail"
                                onClick={() => spotifyUrlOpen(video.spotifyUrl)}
                                title={`open Spotify url ${video.spotifyUrl}`}
                            />

                            <div className="spotify-video-info">
                                <p className="spotify-tags">
                                    {video.tags.map(tag => (
                                        <span key={tag} className="tag">{tag}</span>
                                    ))}
                                </p>
                                <h4>{video.title}</h4>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
};

export default VideoSwiperTemplate;



