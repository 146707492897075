import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://resumecv.idea2reality.tech/api/',
});

axiosInstance.interceptors.request.use(
    (config) => {
     const token = localStorage.getItem('LoginToken'); 
     if (token) {
         config.headers['Authorization'] = `Bearer ${token}`;
     }
     return config;
    },
    (error) => {
     return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
        const { response } = error;
        if (response && response.status === 401) {
            console.error('Unauthorized access - redirecting to login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;