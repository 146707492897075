/* eslint-disable */

import {
  Box,
  Flex,
  HStack,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
// import Card from 'components/card/Card';
import Card from "../../../../components_sec/card/Card";
import Menu from "../../../../components_sec/menu/MainMenu";
import * as React from "react";
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { SELECTTEMPLATE,
  ADDEXPERIENCEALL,
  ADDEDUCATION,
  ADDPERSONALINFO,
  EDITSKILL,
  SELECTRESUME,
} from "../../../../Redux/Actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import JsPDF from "jspdf";

const columnHelper = createColumnHelper();

const mapStateToProps = (state) => ({
  selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
  setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
  onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
  onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
});

// const columns = columnsDataCheck;
function ComplexTable(props) {
  const { tableData, dashboardData, currentPage, setCurrentPage,loading } = props;
  const [sorting, setSorting] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData?.data;

  const setUserData = (resume) => {
    props.onADDPERSONALINFO(resume.personalInfo);
    props.setADDEXPERIENCEALL(resume.experiences);
    props.onADDEDUCATION(resume.educationInfo);
    props.onEDITSKILL(resume.skills);
  };

  const navigateToFillDetails = (data) => {
  //    const updatedResumeData = {
  //     educationInfo:data?.education,
  //     experiences:data?.work_experience,
  //     skills:data?.skills?.map((val)=>val?.skills),
  //     template_id: data?.template_id,
  //     id:data?.id,
  //     personalInfo: {
  //     address: data?.address,
  //     country: data?.country?.country_name,
  //     country_id: data?.country_id,
  //     city:data?.city?.city_name,
  //     city_id: data?.city_id,
  //     email: data?.email,
  //     firstName: data?.first_name,
  //     lastName: data?.last_name,
  //     phone: data?.phone,
  //     country_code:data?.country_code,
  //     objective:data?.objective,
  //     postalCode: data?.postal_code,
  //     profile:data?.profile,
  //     state: data?.state?.state_name,
  //     state_id: data?.state_id,
  //   },     
  // }
  //   props.setSelectedTemplateId(updatedResumeData?.template_id);
  //   props.setSelectedResumeId(updatedResumeData?.id);
  //   setUserData(updatedResumeData);
    navigate("/MyResume");
  };

  const downloadResume = (id) => {
    const report = new JsPDF("p", "pt", "a4");
    report.html(document.getElementById(`${id}report`)).then(() => {
      report.save(`resume.pdf`);
    });
  };

  const columns = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400" >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400">
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("public_link", {
      id: "public_link",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400" >
          Public Link
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Link to={`/publiclink/${info?.row?.original?.uuid}`} target="_blank">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() ? info.getValue() : "-"}
          </Text>
          </Link>
        </Flex>
      ),
    }),

    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400">
          DATE
        </Text>
      ),
      cell: (info) => {
        // Format the 'created_at' value using moment.js
        const formattedDate = moment(info.getValue()).format("DD MMM YYYY");
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formattedDate}
          </Text>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400">
          Action
        </Text>
      ),
      cell: (info) => (
        <Box display="flex" gap={2}>
          <Button
            onClick={() => navigateToFillDetails(info.row.original)}
            colorScheme="blue" size="xs">
            Builder
          </Button>

          <Button onClick={() => downloadResume("0")}
            colorScheme="green" size="xs">
            Download PDF
          </Button>
        
        </Box>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  // Pagination Logic
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }} // Responsive scrolling for smaller screens
    >
      {/* <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
    <Text
      color={textColor}
      fontSize="22px"
      fontWeight="700"
      lineHeight="100%">
       Resume
    </Text>
    <Menu />
  </Flex> */}
      <Box>
        {loading ? ( // Show spinner if loading is true
          <Flex justify="center" align="center" minH="200px">
            <Spinner size="lg" /> {/* Loader spinner */}
          </Flex>
        ) : (
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups()?.map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup?.headers?.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}>
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }} // Font size varies by screen size
                        color="gray.400">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "", // Indicate sort state
                          desc: "", // Placeholder for sorting icons if needed
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel()
                .rows.slice(0, 11)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}

        <HStack justify="center" spacing={4} mt={4}>
          {/* <Flex px="25px" mb="8px" alignItems="center">
        <Text mr="10px"  mb="0px" p="0px" color={textColor}>
          Show per row:
        </Text>
        <Select
          value={rowsPerPage}
          onChange={(e) => {
            setRowsPerPage(Number(e.target.value)); // Update rows per page
            // Reset current page to 1 when changing rows per page
            if (currentPage > Math.ceil(paginatedRows.length / Number(e.target.value))) {
              handlePrevPage(); // Go back to the previous page if needed
            }
          }}
          width="100px"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
      </Flex> */}

          <div>
            {tableData?.data?.length < tableData?.total && (
              <div className="pagination-container">
                <ReactPaginate
                  pageCount={Math.ceil(tableData?.total / tableData?.per_page)}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="selected"
                  disabledClassName="disabled"
                />
              </div>
            )}
          </div>
          {/* <Button onClick={handlePrevPage} isDisabled={currentPage === 1}>
            Previous
          </Button>
          <Text m="0px" p="0px" >
            Page {currentPage} of {totalPages}
          </Text>
          <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
            Next
          </Button> */}
        </HStack>
      </Box>
    </Card>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplexTable);