import React from 'react';
import "../assets/Styles/Home.css";
import Section1 from '../NewComponents/Home/Section1';
import Section2 from '../NewComponents/Home/Section2';
import Section3 from '../NewComponents/Home/Section3';
import Section4 from '../NewComponents/Home/Section4';
import Section5 from '../NewComponents/Home/Section5';
import Section6 from '../NewComponents/Home/Section6';
import Section7 from '../NewComponents/Home/Section7';
import Section8 from '../NewComponents/Home/Section8';
import Section9 from '../NewComponents/Home/Section9.jsx';
import Section10 from '../NewComponents/Home/Section10.jsx';
import Section11 from '../NewComponents/Home/Section11.jsx';
import SwiperTemplate from "../NewComponents/Home/SwiperTemplate.jsx"
import VideoSwiperTemplate from "../NewComponents/Home/VideoSwiperTemplate.jsx"
import SpotifySwiperTemplate from "../NewComponents/Home/SpotifySwiperTemplate.jsx"



const Home = () => {

    return (
        <>
            <Section1 />

            <Section2 />

            <Section3 /> 

            <Section9 /> 

            <Section4 /> 

            <SwiperTemplate />

            <Section5 />  

            <VideoSwiperTemplate /> 

             <Section10 />

            {/* <Section6 />  */}

            <Section11 />

            <SpotifySwiperTemplate />

            <Section8 />

            <Section7 />

        </>
    )
}

export default Home;