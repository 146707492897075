import { Link, useNavigate } from "react-router-dom"
import Navlogo from "../../assets/Images/NavLogo.png"
import "../../assets/Styles/LoginRegisterHeader.css"


const LoginRegisterHeader = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className="LoginRegisterHeader_main">
                <div>
                    <Link to="/" className="logo-link">
                        <img
                            src={Navlogo}
                            style={{ height: 20 }}
                            alt="logo"
                        />
                    </Link>
                </div>

                <button className="LoginRegisterHeader_button" onClick={() => navigate("/")}>
                    Home
                </button>
            </div>

        </>
    )
}
export default LoginRegisterHeader