import React, { useState } from 'react';
// import NavBar from '../Components/NavBar/NavBar';
import NavBar from '../Components/NewNavbar/NavBar'
import FillDetailsSidebar from '../Components/FillDetailsSidebar';
import WorkExperienceComponent from '../Components/WorkExpComp';
import EducationComp from '../Components/EducationComp';
import "../assets/Styles/Fill_Details.css";
import KeySkillComp from '../Components/keySkillComp';
import PersonalInfoComp from '../Components/PersonalInfoComp';
import PreviewComp from '../Components/PreviewComp';

const FillDetails = (props) => {
    const [tab, setTab] = useState(0);
    return (
        <>
            <div className="fillDetails d-flex">
                {tab === 4 ? null : (
                    <div className="col-6 details-filling">
                        <FillDetailsSidebar tab={tab} setTab={setTab} />
                        {tab === 0 ? (
                            <PersonalInfoComp setTab={setTab} tab={tab} />
                        ) : null}
                        {tab === 3 ? < KeySkillComp setTab={setTab} tab={tab} /> : null}

                        {tab === 1 ? (
                            <WorkExperienceComponent setTab={setTab} tab={tab} />
                        ) : null}
                        {tab === 2 ? <EducationComp setTab={setTab} tab={tab} /> : null}
                    </div>
                )}
                <div className="col-6">
                    <PreviewComp setTab={setTab} tab={tab} />
                </div>
            </div>

        </>
    )
}
export default FillDetails;