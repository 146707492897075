import { Link } from "react-router-dom";
import React, { useState } from "react";
import { email_ForgotPassword } from "../../api/services/userService";
import { toast } from "react-toastify";

const ForgotWithEmail = ({ setOtpSection, setEmailOtp,email,setEmail}) => {
  // const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sendEmailOtpBtnLoading, setSendEmailOtpBtnLoading] = useState(false);

  const onEmailFormSubmit=async(e)=>{
    e.preventDefault();
    if (!email || email.trim() === "") {
      setEmailError("Email is required");
    } else {
      setEmailError("")
      setSendEmailOtpBtnLoading(true);
      const fData ={
        email:email
      }
      try{
      const response = await email_ForgotPassword(fData); 
       if(response?.success){
        toast.success(response?.message, {
         position: "top-center",
        });
        setEmailOtp(response?.otp);
        setOtpSection(true)}
      }catch(error){
        toast.error(error?.response?.data?.error,{
         position: "top-center",
        });
      }finally{
        setSendEmailOtpBtnLoading(false);
      }
    }
  };

  return (<>
      <form className="mt-2 p-0" onSubmit={onEmailFormSubmit}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <span className="" style={{ color: "red", fontSize: "13px" }}>
                {emailError}
              </span>
            )}
          </div>

        <div className="d-flex flex-column">
          <button type="submit" className="login-signup-btn mt-4">
            {
             sendEmailOtpBtnLoading &&
             <div className="spinner-border text-light" style={{height:15,width:15}}
              role="status"> </div>
            }
            Submit
          </button>
          <Link to="/login" className="register-btn mt-4">
            Back To Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotWithEmail;
