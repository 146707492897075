
import { Box, SimpleGrid,Button} from "@chakra-ui/react";
import ComplexTable from "../dataTables/components/ComplexTable";
import {
  // columnsDataDevelopment,
  // columnsDataCheck,
  // columnsDataColumns,
  columnsDataComplex,
} from "../dataTables/variables/columnsData";
import tableDataComplex from "../dataTables/variables/tableDataComplex.json";
import React, { useEffect } from "react";
import "../../../assets/Styles/PricingPlan.css";
import img2 from "../../../assets/Images/section4-2.png";
import { Link } from "react-router-dom";


export default function Settings() {


  useEffect(() => {
    loadRazorpay();
  }, []);

  const loadRazorpay = () => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  };

  const handlePayment = (amount) => {
    const options = {
      key: "rzp_test_IcgTNsIE5vV4DM",
      key_secret: "yHf8cImq5DtzflsmjgsuJApS",
      amount: amount * 100,
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Test Transaction',
      image: 'https://your-logo-url.com/logo.png',
      // order_id: 'YOUR_ORDER_ID',
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Your Address',
      },
      theme: {
        color: '#F37254',
      },
    };
    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <div className="d-flex justify-content-end mt-2"> 
      <Link to="/admin/transactions">
        <Button colorScheme="blue" size="sm">
          Transactions
        </Button>
      </Link>
      </div>

      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>

        <div className="pricing-section">
          <div className="pricing-header">
            <h1>Flexible Plans & Pricing</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry and has been the standard ever since.
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti, aliquid error? Ipsam reiciendis,
              voluptatibus veritatis, fugiat adipisci nemo dolore ea possimus ducimus ipsum numquam deleniti
              totam aliquid? Sed, autem maiores.</p>
          </div>
          <div className="pricing-cards-container">
            {/* {/ Personal Plan /} */}
            <div className="pricing-card">
              <div className="card-header">
                <div className="d-flex justify-content-center">
                  <img src={img2} alt="Personal Icon" />
                </div>
                <h2>Free</h2>
                <h3>₹500 / month</h3>
              </div>
              <ul className="card-details">
                <li>Standard Dummy Text</li>
                <li>Randomized Words</li>
                <li>Free Event Passes</li>
              </ul>
              <button className="subscribe-btn" onClick={() => handlePayment('500')}>BUY NOW</button>
            </div>

            {/* {/ Team Plan /} */}
            <div className="pricing-card">
              <div className="card-header">
                <div className="d-flex justify-content-center">
                  <img src={img2} alt="Team Icon" />
                </div>
                <h2>Silver</h2>
                <h3>₹800 / month</h3>
              </div>
              <ul className="card-details">
                <li>All benefits of Personal</li>
                <li>Various Versions</li>
                <li>Unlimited Storage</li>
              </ul>
              <button className="subscribe-btn" onClick={() => handlePayment('800')}>BUY NOW</button>
            </div>

            {/* {/ Corporate Plan /} */}
            <div className="pricing-card">
              <div className="card-header">
                <div className="d-flex justify-content-center">
                  <img src={img2} alt="Corporate Icon" />
                </div>
                <h2>Gold</h2>
                <h3>₹1000 / month</h3>
              </div>
              <ul className="card-details">
                <li>All benefits of Team</li>
                <li>Sentence Structure</li>
                <li>Lifetime Access</li>
              </ul>
              <button className="subscribe-btn" onClick={() => handlePayment('1000')}>BUY NOW</button>
            </div>
          </div>
        </div>
      </SimpleGrid>
    </Box>
  );
}
