import React from 'react';
import "../../assets/Styles/PricingPlan.css";
import img2 from "../../assets/Images/section4-2.svg"

const PricingPlan = () => {
    return (
        <div className="pricing-section">
            <div className="pricing-header">
                <h2>Choose your membership plan</h2>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry and has been the standard ever since.
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti, aliquid error? Ipsam reiciendis,
                    voluptatibus veritatis, fugiat adipisci nemo dolore ea possimus ducimus ipsum numquam deleniti
                    totam aliquid? Sed, autem maiores.</p> */}
            </div>
            <div className="pricing-cards-container">
                {/* Personal Plan */}
                <div className="pricing-card">
                    <div className="card-header">
                        {/* <img src={img2} alt="Personal Icon" /> */}
                        {/* <h2>Free</h2> 
                        <h3>₹500 / month</h3>*/}
                        <h2>7-day Trial</h2>
                        <h3>₹500</h3>
                    </div>
                    <ul className="card-details">
                        <li>Good start</li>
                        <li>Standard Dummy Text</li>
                        <li>Randomized Words</li>
                        <li>Free Event Passes</li>
                    </ul>
                    <button className="subscribe-btn">BUY NOW</button>
                </div>
                {/* Team Plan */}
                <div className="pricing-card">
                    <div className="card-header">
                        {/* <img src={img2} alt="Team Icon" /> */}
                        {/* <h2>Silver</h2> 
                         <h3>₹800 / month</h3>*/}
                        <h2>6 Months</h2>
                        <h3>₹1843.5</h3>
                    </div>
                    <ul className="card-details">
                        <li>Only ₹307.25 per month</li>
                        <li>All benefits of Personal</li>
                        <li>Various Versions</li>
                        <li>Unlimited Storage</li>
                    </ul>
                    <button className="subscribe-btn">BUY NOW</button>
                </div>
                {/* Corporate Plan */}
                <div className="pricing-card">
                    <div className="card-header">
                        {/* <img src={img2} alt="Corporate Icon" /> */}
                        {/* <h2>Gold</h2>
                        <h3>₹1000 / month</h3> */}
                        <h2>1 Year</h2>
                        <h3>₹2899</h3>
                    </div>
                    <ul className="card-details">
                        <li>Only ₹241.63 per month</li>
                        <li>All benefits of Team</li>
                        <li>Sentence Structure</li>
                        <li>Lifetime Access</li>
                    </ul>
                    <button className="subscribe-btn">BUY NOW</button>
                </div>
            </div>
        </div>
    );
};

export default PricingPlan;
