import { Container } from "@mui/system";
import React from 'react';
import "../assets/Styles/TemplateExperienceComp.css";

const TemplateExperienceComp = (props) => {
    return (
        <Container className="templateExperienceComp">
            <li className="templateExperienceComp">
                <h3 className="experience-heading">
                    {props.experiences.job_title}
                    <span className="org-name">
                        {props.experiences.org_name}
                    </span>
                    <span className="experience-start-end">
                        ({props.experiences.start_year} - {props.experiences.end_year})
                    </span>
                </h3>
            </li>
        </Container>
    )
}

export default TemplateExperienceComp;
