import { useEffect } from "react";
import { isTokenValid } from "./AuthCheck";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";


const PrivateRoute = (props) => {

    const { Component } = props
    const isValid = isTokenValid();
    const navigate = useNavigate();

    const authCheck = useSelector((state) => state?.AuthCheck?.isSessionExpired)

    useEffect(() => {
        if (!authCheck) {
            if (!isValid) {
                navigate('/login')
            }
        }
    })

    return (
        <div>
            <Component />
        </div>
    )
}
export default PrivateRoute;