import "../../assets/Styles/Section1.css";
import { GoDotFill } from "react-icons/go";

import accenture from "../../assets/Images/accenture.svg"
import amazon from "../../assets/Images/amazon.svg"
import americanexpress from "../../assets/Images/americanexpress.svg"
import apple from "../../assets/Images/apple.svg"
import booking from "../../assets/Images/booking.svg"
import dhl from "../../assets/Images/dhl.svg"
import kpmg from "../../assets/Images/kpmg.svg"


import img1 from "../../assets/Images/star_1.webp"
import img2 from "../../assets/Images/star_2.webp"
import img3 from "../../assets/Images/star_5.webp"
import img4 from "../../assets/Images/frame_1.webp"
import img5 from "../../assets/Images/frame_2.webp"
import img6 from "../../assets/Images/frame_3.webp"
import img7 from "../../assets/Images/badge.webp"
import img9 from "../../assets/Images/copenhagen_resized.webp"
import img10 from "../../assets/Images/input.webp"


import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Section1 = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(true);

    const createResume = () => {
        navigate('/templatecard')
    }


    useEffect(() => {
        const interval = setInterval(() => {
            setVisible((prev) => !prev);
        }, 800);

        return () => clearInterval(interval);
    }, [])
    return (
        <>
            <div className="resume-builder-section-1">

                <div className="resume-sec1-flex">
                    <div className="hero-resume-sec1-div1">
                        <div className="dot-class-resume-created d-flex align-items-center"><span className={`dot ${visible ? "visible" : "hidden"}`}> <GoDotFill size={12} color="#339d5d" /></span> 34,664 resumes created today</div>
                        <div className="sec1-title"><h1>The professional resume builder</h1></div>
                        <div className="sec1-subtitle"><p>Only 2% of resumes win. Yours will be one of them. Let’s build you a resume that works.</p></div>

                        <div>
                            <button className="resume-sec1-btn" onClick={createResume}>Create my resume</button>
                        </div>

                        <div className="d-flex gap-5 align-items-center mt-5 mb-3">
                            <div className="d-flex align-items-center gap-3">
                                <div className="vertical-line-sec1"></div>
                                <div>
                                    <div className="sec-1-39">39%</div><span className="sec1-39-8-text">more likely to get hired</span>
                                </div>
                            </div>

                            <div className="d-flex align-items-center gap-3">
                                <div className="vertical-line-sec1 "></div>
                                <div>
                                    <div className="sec-1-8">8%</div><span className="sec1-39-8-text">better pay with your next job</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-resume-sec1-div2">
                        <div className="sec-1_background-sparkles">

                            <div className="sec-1_toolbar">
                                <img src={img9} alt="Resume" />
                            </div>

                            <div className="sec-1_resume">
                                <img src={img10} alt="Input" />
                            </div>

                            <div className="sec-1_export-options">
                                <img src={img6} alt="frame_3" />
                            </div>

                            <div className="sec-1_ai-writer">
                                <img src={img7} alt="badge" />
                            </div>

                            <div className="sec-1_star_2">
                                <img src={img2} alt="star_2" />
                            </div>

                            <div className="sec-1_frame_2">
                                <img src={img5} alt="frame_2" />
                            </div>

                            <div className="sec-1_star_1">
                                <img src={img1} alt="star_1" />
                            </div>

                            <div className="sec-1_star_5">
                                <img src={img3} alt="star_5" />
                            </div>

                            <div className="sec-1_frame_1">
                                <img src={img4} alt="frame_1" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="company-logo-div">
                Our customers have been hired at:

                <div className="trusted-logos">
                    <div> <img src={booking} alt="booking" /> </div>

                    <div> <img src={apple} alt="apple" /> </div>

                    <div> <img src={dhl} alt="dhl" /> </div>

                    <div> <img src={amazon} alt="amazon" /> </div>

                    <div> <img src={americanexpress} alt="americanexpress" /> </div>

                    <div> <img src={accenture} alt="accenture" /> </div>

                    <div> <img src={kpmg} alt="kpmg" /> </div>
                </div>
            </div>
        </>
    )
}

export default Section1;