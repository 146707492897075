
const API_BASE_URL = 'https://resumecv.idea2reality.tech/api/'; // Replace with your API base URL

const API_CONFIG = {
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
};

export default API_CONFIG;