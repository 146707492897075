import { ThreeCircles } from "react-loader-spinner";

export const Loader1 = () => {
  return (
    <>
      <ThreeCircles
        visible={true}
        height="80"
        width="80"
        color="#951dc1"
        ariaLabel="three-circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </>
  );
};
